import React from 'react';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import './NotFount.less';

import Page from '../../../components/page/PageIndex';
import { getHtmlTDK } from '../../../lib/utils/UtilsCommon';

/**
 * state
 */
interface IState {
	htmlTDK: {
		title: string;
	};
}

/**
 * NotFount page
 *
 * @class NotFount
 * @extends {React.Component<any>}
 */
@autobind
class NotFount extends React.Component<any, any> {
	constructor(props: any, context?: any) {
		super(props, context);

		const state: any = {
			htmlTDK: {
				title: '学兴趣就上兴趣岛——名师更多，课程更全',
				pageDescription: '兴趣岛兴趣学堂-名师更多，课程更全。摄影、瑜伽等30门主流课程21门学员数量全国领先。',
				pageKeyword: '兴趣,兴趣岛,兴趣岛兴趣学堂,兴趣学堂,千聊兴趣岛,兴趣岛-名师更多，课程更全,兴趣岛兴趣学堂-名师更多，课程更全,千聊,知识付费,内容付费,千聊特训营,成人教育',
			},
			leftTime: 3,
		};

		this.state = state;
	}

	/**
	 * 动态渲染数据放这里处理
	 *
	 * @static
	 * @param {*} { req }
	 * @returns {Promise<any>}
	 * @memberof NotFount
	 */
	public static async getInitialProps(context: any): Promise<any> {
		const { query, params } = context.req;

		return {};
	}

	/**
	 * 客户端调用获取数据
	 *
	 * @memberof NotFount
	 */
	public async componentDidMount(): Promise<any> {
		setTimeout(this.initTimer, 1000);

		return {};
	}

	/**
	 * 倒计时
	 */
	public initTimer(): void {
		let leftTime: number = this.state.leftTime - 1;
		if (leftTime > 0) {
			this.setState(
				{
					leftTime,
				},
				(): void => {
					setTimeout(this.initTimer, 1000);
				}
			);
		} else {
			(window as any).location.href = '/';
		}
	}

	/**
	 * 渲染页面
	 *
	 * @returns {*}
	 * @memberof NotFount
	 */
	public render(): any {
		return (
			<Page {...getHtmlTDK(this.state.htmlTDK)} bodyClass="m-not-fount" region="page-not-fount">
				<img src="https://img.qlchat.com/qlLive/activity/image/3P9HW64K-11JQ-GFRZ-1610074866256-NCUTQSIZKVI8.png?x-oss-process=image/format,webp" alt="" />
				<p className="tips">页面链接可能已失效或被删除</p>
				<a className="back-btn" href="/">
					回到首页({this.state.leftTime}s)
				</a>
			</Page>
		);
	}
}

/**
 * mapStateToProps
 */
function mapStateToProps(state: any): any {
	return {};
}

const mapActionToProps: any = {};

export default connect(mapStateToProps, mapActionToProps)(NotFount);
